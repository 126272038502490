.template_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.template_list-item {
  padding: 7px 0;
  & + & {
    border-top: 1px solid #ddd; } }

.template_list-date {
  font-size: 12px;
  margin-bottom: 4px; }

.template_list-title {
  @include font-title;
  font-size: 14px;
  margin-bottom: 5px; }

.template_list-title-link {
  text-decoration: none; }

//.template_list-attachments

//.template_list-attachment

.template_list-attachment-link {
  color: #747776;
  margin-top: 5px;
  font-size: 14px; }
