.n-size-overview {
  display: inline-block;
  margin-left: 5px;
  &.-error {
    background: $color-danger;
    padding: 0 10px;
    border-radius: 20px;
    color: #fff; }
  & + .info-tooltip {
    margin-left: 3px; } }
