.tabs {
  margin: 15px 0; }

.tabs_list {
  display: inline-flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: $color-gray;
  padding: 2px;
  margin: 0;
  list-style: none;
  border-radius: 50px; }
.tabs_item {
  flex: 0 0 auto;
  & + & {
    margin-left: 10px; } }

.tabs_button {
  @include button-reset;
  border-radius: 50px;
  color: #343333;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  padding: 10px 15px;
  text-transform: uppercase;
  &.-active {
    color: $color-white;
    background-color: $color-black; } }





