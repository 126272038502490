.header {
  background-color: $color-black;
  color: $color-white; }

.header_container {
  @include container; }

.header_menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ($base-gap + 5px);
  margin: 0 -15px;
  border-bottom: 1px solid #333;
  list-style: none; }

.header_menu-item {
  padding: 5px 15px; }

.header_menu-link {
  color: $color-white;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  &.-active {
    font-weight: bold; } }

.header_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px ($base-gap + 5px); }

.header_title-logo {
  margin-right: 30px; }

.header_logo {
  width: 35px;
  height: 43px;
  color: $color-brand; }

.header_title-text {
  @include font-title;
  font-size: 40px; }
