.doughnut {
  position: relative;
  min-width: 190px;
  &.-in-table {
    min-width: 0;
    svg {
      overflow: visible; } }

  .inside-percent {
    font-family: "NHaasGroteskDisplay", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    fill: #fff;
    &.-black {
      fill: #000; } } }

.doughnut_legend {
  position: absolute;
  right: 0;
  left: 132px;
  top: 0;
  &.-separate {
    position: static; } }

.doughnut_legend-item {
  position: relative;
  padding-left: 12px;
  & + & {
    margin-top: 5px; } }

.doughnut_legend-icon {
  position: absolute;
  width: 7px;
  height: 7px;
  left: 0;
  top: 3px;
  border-radius: 2px;
  background-color: currentColor; }

.doughnut_legend-text {
  font-size: 10px;
  line-height: 1.2; }
