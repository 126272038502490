.customers-search_form {
  position: sticky;
  top: -10px;
  z-index: 2;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: $color-white;
  padding-top: 10px; }

.customers-search_list {
  list-style: none;
  padding: 0;
  margin: 15px 0 0; }

.customers-search_item {
  & + & {
    padding-top: 15px;
    margin: 15px 0;
    border-top: 1px solid #d5d6d6; } }

.customer-search {
  color: $color-black;
  position: relative;
  &.-selected {
    background-color: $color-gray;
    border-color: $color-gray; } }

.customer-search_grid {
  display: flex;
  justify-content: flex-start;
  &.-no-flex {
    display: block; }
  .draft-wrapper-custom {
    margin-bottom: 15px; } }

.customer-search_avatar {
  width: 90px;
  height: 90px;
  overflow: hidden;
  flex: 0 0 auto;
  background: $color-black;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; } }

.customer-search_info {
  flex: 1 1 auto;
  margin: 0 32px;
  &.-edit, &.-right-col {
    margin-left: 15px;
    margin-right: 0;
    &.-no-left-margin {
      margin-left: 0; } }
  &.-left-col {
    flex: 0 0 auto;
    min-width: 30%;
    margin-left: 0;
    margin-right: 5px; }
  &.-full-height {
    .form-group, .form-group_control, textarea {
      height: 100%; } } }

.customer-search_name {
  @include font-title;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
  input {
    font-size: 14px; } }

.customer-search {
  .DraftEditor-root {
    padding-left: 14px;
    padding-right: 14px; }
  .draft-editor-custom {
    &.-brief-overview {
      font-size: 15px; }
    &.-base-info {
      font-size: 15px; }
    &.-install-type {
      font-size: 15px; }
    &.-description {
      font-size: 15px; }
    &.-experience {
      font-size: 15px; } } }

.customer-search_button {
  max-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button + button {
    margin-top: 5px; } }

.customer-search_text {
  font-size: 14px;
  min-height: 50px;
  & + & {
    margin-top: 5px; } }

.customer-search + h3 {
  margin-top: 25px; }
