.table-pulses {
  width: auto;
  table-layout: auto;
  border-collapse: collapse; }

.table-pulses_section {
  border-bottom: 30px solid transparent;
  &.-satisfaction {
    border-bottom-width: 10px; } }

.table-pulses_col {
  min-width: 90px;
  padding: 8px 12px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  &.-right {
    text-align: right; }
  &.-first {
    width: 210px;
    text-align: left;
    padding-left: 0; }
  &:last-child {
    padding-right: 0; }
  &.-head {
    vertical-align: bottom;
    text-align: left; }
  .doughnut_legend {
    max-width: 120px; }
  .doughnut_legend-text {
    font-size: 14px; } }

th.table-pulses_col {
  padding: 0; }

.table-pulses_head-inner {
  border-bottom: 2px solid $color-black;
  &.-number {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 8px; } }

.table-pulses_title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  @include font-title;
  margin: 0 0 8px;
  &.-center {
    text-align: center; } }

.table-pulses_subtitle {
  font-size: 14px;
  margin: -5px 0 5px;
  font-weight: bold;
  text-align: center; }

.table-pulses_text {
  &.-big {
    @include font-title;
    font-size: 30px;
    line-height: 1.2;
    font-weight: bold;
    padding: 20px 0;
    white-space: nowrap; }
  &.-md {
    @include font-title;
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    white-space: nowrap; } }

