.percent-report {
  padding-bottom: 24px;
  &.-many-answers {
    .slick-dots {
      button {
        width: 9px;
        height: 9px; } } } }

.percent-report_value {
  @include font-title;
  font-weight: bold;
  font-size: 45px;
  line-height: 1;
  margin-bottom: 20px;
  height: 88px;
  display: flex;
  align-items: center;
  &.-with-filters {
    flex-direction: column; }
  .filtered-values {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    width: 50%;
    margin-top: 10px;
    div {
      text-align: center;
      width: 50%;
      &:first-child {
        color: $color-mm-wave; }
      &:last-child {
        color: $color-c-band; } } } }

.active-report_item {
  &.-centered {
    .percent-report_value {
      justify-content: center; } } }

.percent-report_name {
  font-size: 10px;
  text-transform: lowercase;
  .as-is {
    text-transform: none; } }
