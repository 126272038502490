.form-control {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  outline: none;
  font-size: 13px;
  padding: 12px 18px;
  background-color: $color-white;
  margin: 0;
  line-height: 1.3;
  border: 1px solid $color-black;
  border-radius: 0;
  box-shadow: none;
  color: $color-black;
  -webkit-appearance: none;
  transition: border-color  150ms ease;
  &.-light {
    border-color: $color-gray; }
  &:disabled {
    background-color: #D8DADA; } }

select.form-control {
  padding-right: 48px; }

textarea.form-control {
  height: calc(3em*1.3 + 2*12px);
  resize: none;
  overflow: hidden;
  overflow-y: auto;
  &.-small-height {
    min-height: 70px; }
  &.-more-height {
    min-height: 110px; }
  &.-big-height {
    min-height: 200px; } }

.form-control::placeholder {
  color: $color-gray-dark; }

.form-control:focus {
  border-color: #000; }
