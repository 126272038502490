*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%; }

body {
  background: $color-white;
  font-size: 15px;
  line-height: 1.4;
  color: $color-text-primary;
  @include font-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 980px; }

a {
  color: inherit; }

button, input, optgroup, select, textarea {
  font-family: $font-text; }

h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  @include font-title;
  font-weight: bold; }

h1 {
  font-size: 32px; }

h2 {
  font-size: 32px; }

.bold {
  font-weight: bold; }

//move styles to separate file
//.plate
//  background-color: $color-plate-bg
//  padding: $base-gap
//  overflow: hidden
//  overflow-y: auto
//
//.plate_title
//  +font-title
//  font-size: 14px
//  text-transform: uppercase
//  margin: 0 0 10px
//  font-weight: bold

.section {
  & + & {
    margin-top: 50px; } }

.section_top {
  margin: 0 0 15px;
  &.-grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  &.-no-bottom-margin {
    margin-bottom: 0;
    & + .customer-spotlight-note {
      margin-top: 10px;
      margin-bottom: 30px; } } }

.section_top-item {
  & + & {
    margin-left: 20px; } }

.section_title {
  @include font-title;
  font-size: 32px;
  margin: 0;
  display: inline-block;
  &.-reduce-no-pulse {
    font-size: 23px; } }

.years_title {
  @include font-title;
  font-size: 24px;
  margin: 0;
  display: inline-block;
  &.-modal {
    margin-top: 10px;
    font-size: 16px;
    .year + .year {
      margin-left: 8px; } }

  .year {
    color: $color-text-secondary;
    cursor: pointer; }

  .year.-active {
    color: $color-text-primary;
    border-bottom: 2px solid $color-brand;
    cursor: default; }

  .year + .year {
    margin-left: 20px; } }

.section_top-button-edit {
  @include button-reset;
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  position: relative;
  top: -5px; }

.section_top-button-edit-icon {
  width: 22px;
  height: 22px;
  &.-mini {
    width: 15px;
    height: 15px; }
  &.-for-file {
    position: relative;
    top: -3px;
    margin-left: 3px;
    cursor: pointer; } }

.section_footer {
  margin-top: 30px; }

.preview-warning {
  position: fixed;
  top: 10px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  z-index: 10;

  color: #D52B1E;
  border: 2px solid #D52B1E;
  background: #e9e9c6;
  border-radius: 5px;
  font-size: 24px;
  text-align: center; }
