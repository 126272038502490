.slick-slide {
  overflow: hidden; }

.slick-dots {
  list-style: none;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 10px;
  margin: 0;
  text-align: center;
  position: absolute;
  top: 100%;
  width: 100%;
  li {
    display: inline-block;
    vertical-align: bottom;
    margin: 5px;
    line-height: 0.5; }
  button {
    @include button-reset;
    text-align: left;
    text-indent: -1000px;
    overflow: hidden;
    position: relative;
    width: 14px;
    height: 14px;
    border-bottom: 1px solid $color-black; }
  .slick-active button {
    border-bottom-width: 3px; } }

.slick-next {
  @include button-reset;
  position: absolute;
  text-align: left;
  text-indent: -1000px;
  overflow: hidden;
  top: 30px;
  right: 0;
  width: 24px;
  height: 24px;
  background-image: url('../assets/images/icons/verizon/i-arrow-stick-right.svg');
  z-index: 2; }
.slick-prev {
  position: absolute;
  display: none;
  visibility: hidden; }





