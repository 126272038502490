.draft-editor-custom {
  &.middle-height {
    height: 110px;
    max-height: 110px; }
  &.more-than-middle-height {
    height: 200px;
    max-height: 200px; }
  &.biggest-height {
    height: 500px;
    max-height: 500px; }
  ul, ol {
    margin: 10px 0 0 -5px; }
  .public-DraftStyleDefault-block {
    margin: 0; }
  li + li {
    margin-top: 5px; } }

.rdw-editor-toolbar {
  margin-bottom: 0; }

.draft-editor-custom {
  border: 1px solid #000; }

.DraftEditor-root {
  padding: 5px;
  height: calc(100% - 1px);
  margin-top: 0; }

.public-DraftEditorPlaceholder-root {
  top: 6px; }

.rdw-fontsize-wrapper {
  .rdw-dropdown-selectedtext {
    span {
      text-align: center;
      width: 100%;
      padding-right: 16px; } }
  .rdw-fontsize-dropdown {
    min-width: 80px; }
  .rdw-dropdown-carettoopen {
    top: 45%; }
  .rdw-dropdown-carettoclose {
    top: 42%; } }

.rdw-colorpicker-wrapper {
  .rdw-colorpicker-modal-style-label-active {
    border-bottom: 3px solid #D52B1E; }
  .rdw-colorpicker-modal-options {
    overflow: auto; } }
