.n-in-progress {
  font-size: 12px;
  margin-top: -1px;
  display: block; }

.exports-header {
  margin-top: 5px; }

.exports-list {
  padding: 0;
  margin: 15px 0 0;
  min-height: 300px;
  li {
    padding: 15px 10px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D8D9D9;
    &.-no-items {
      border: none;
      padding: 0; }

    a {
      text-decoration: none; }

    .completed {
      flex: 1 auto; }

    .icon-col {
      margin-left: 10px;

      a {
        display: inline-block;
        background-color: #000;
        color: #fff;
        border-radius: 20px;
        padding: 7px 19px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px; }
      a + a {
        margin-left: 5px; } }

    .-process-completed {
      width: 15px;
      height: 17px;
      color: #000; }

    .-process-fail {
      width: 18px;
      height: 18px;
      color: $color-danger; }

    .-process-pending {
      color: #000;
      display: inline-block;
      width: 18px;
      height: 18px;
      animation: processing-spinner 2s infinite linear; }

    @keyframes processing-spinner {
      0% {
        transform: rotate(0); }
      100% {
        transform: rotate(360deg); } }

    span {
      font-size: 14px;
      line-height: 1;
      display: block;
      width: 100%; }
    span + span, a + span {
      margin: 3px 0 0; }
    .date {
      font-size: 10px; }
    .failed {
      color: $color-danger; } }
  li + li {
    margin-top: 5px; }

  &.-open-ends {
    li {
      justify-content: flex-start; }
    .icon-col {
      margin-left: 0;
      margin-right: 10px; }
    label {
      cursor: pointer; }
    .date {
      margin-top: 8px; } } }
