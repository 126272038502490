// Breakpoints

$bp-xs: 375px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xlg: 1440px;

$container-width: 1200px;
$sidebar-width: 200px;

// fonts
$font-title: "NHaasGroteskDisplay", Helvetica, Arial, sans-serif;
$font-text: "NHaasGroteskText", Helvetica, Arial, sans-serif;

$base-gap: 24px;
$cols-gap: 16px;
$sidebar-gap: 16px;

$color-brand: #D52B1E;
$color-black: #000;
$color-white: #fff;
$color-gray: #D8D9D9;
$color-gray-dark: #747776;
$color-gray-dark-2: #6F7171;
$color-danger: #ED7000;
$color-danger-2: #FF8027;
$color-stone: #F3EDE0;
$color-coral: #FF3C2D;
$dropdown-item-background: #343333;
$z-index-tooltip: 2;

$color-plate-bg: $color-gray;

$color-text-primary: $color-black;
$color-text-secondary: $color-gray-dark;
$color-text-accent: $color-brand;

$color-mm-wave: #D52B1E;
$color-c-band: #EF9894;
