.layout {
  @include container;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.content-wrapper {
  flex: 1 1 auto;
  min-width: 0;
  padding: $base-gap $base-gap $base-gap 34px; }

$header-height: 100;
$copyright-height: 88;
.content {
  min-height: calc(100vh - #{$header-height}px - #{$copyright-height}px);
  flex: 1 1 auto;
  min-width: 0; }

.copyright {
  padding-top: $base-gap;
  @include font-text;
  font-size: 12px; }

.layout-over {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-stone;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 50px; }

.layout-over_box {
  flex: 0 0 auto;
  &.-center {
    margin: auto; } }
