$pad-hor: 100px;

.auth-box {
  width: 408px;
  padding: 25px;
  // special styles for form element only in sign in form
  .form-group_label {
    font-size: 15px; }
  .form-group + .form-group {
    margin-top: 24px; }
  .form-control {
    border: 2px solid $color-black;
    height: 38px;
    padding: 8px 12px;
    font-weight: bold; }
  .form-buttons {
    margin-top: 24px; }
  .form-link {
    color: $color-coral;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none; }
  .button {
    font-size: 14px;
    padding: 8px 20px;
    text-transform: uppercase; }
  .form-group_label.-right {
    margin-left: 10px; }
  .checkbox {
    border: 2px solid $color-black;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    margin-top: -1px; }
  input[type="checkbox"] {
    &:checked + .checkbox {
      background-color: #000;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: url("../assets/images/icons/verizon/i-checkbox-v2.svg") no-repeat 50% 50%;
        background-size: auto auto;
        left: 0; } } }
  .portal-error {
    background-color: $color-danger-2;
    margin-top: 0;
    margin-bottom: 24px; } }

.auth-box_header {
  padding-bottom: 70px;
  text-align: center; }

.auth-box_header-logo {
  width: 170px;
  height: 38px; }

.auth-box_title {
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 25px; }

.auth-box_text {
  margin: 0 0 25px;
  font-size: 14px;
  color: $color-black; }

.auth-box_form {
  font-size: 14px;
  color: $color-black; }




.auth-footer_text {
  text-align: center;
  font-size: 13px;
  color: $color-gray-dark-2; }

