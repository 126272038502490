.custom-dropdown + .section-body, .custom-dropdown + .section_top {
  margin-top: 15px; }

.custom-dropdown {
  display: inline-block;
  cursor: pointer;
  position: relative;
  &:hover {
    .selected-text {
      border-bottom-color: $color-brand; } }

  &.-opened {
    .carrot {
      &:before {
        transform: translateY(-50%) rotate(-45deg); }
      &:after {
        transform: translateY(-50%) rotate(45deg); } } }
  .carrot {
    margin-left: 8px;
    position: relative;
    width: 10px;
    &:before, &:after {
      right: 0;
      top: calc(50% - 2px);
      content: '';
      position: absolute;
      height: 2px;
      background: #000;
      width: 7px; }
    &:before {
      transform: translateY(-50%) rotate(45deg);
      right: 4px; }
    &:after {
      transform: translateY(-50%) rotate(-45deg); } }
  .selected {
    display: flex;
    flex-direction: row; }
  .selected-text {
    min-width: 50px;
    display: inline-block;
    border-bottom: 2px solid $color-gray-dark;
    padding-bottom: 4px; }
  .list {
    left: 0;
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    background: #000;
    z-index: 1;
    min-width: 120px;
    li {
      list-style: none;
      color: #fff;
      padding: 8px;
      &.-selected {
        color: $color-gray-dark; }
      &:hover {
        background-color: $dropdown-item-background; } } } }


