.form-group {
  & + & {
    margin-top: 15px; }
  &.-checkbox {
    display: flex;
    align-items: center;
    padding: 3px 0; }
  &.-radio-set {
    h4 {
      text-transform: uppercase; } } }

.form-group_label {
  @include font-title;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;
  &.-upper {
    text-transform: uppercase; }
  &.-thin {
    font-weight: normal; }
  &.-checkbox, &.-radio {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0; }
  &.-radio {
    cursor: pointer; }
  &.-right {
    order: 1;
    margin-left: 5px; } }

.form-group_control {
  &.-video {
    padding-bottom: 40px; }
  &.-button {
    margin-top: 10px; }
  .uploaded-list {
    padding: 0;
    margin: 7px 0 0 0;
    li {
      margin: 0;
      padding: 0 0 0 17px;
      list-style: none;
      font-size: 12px; }
    li + li {
      margin-top: 5px; } }
  &.-for-select {
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      background-color: $color-black;
      width: 10px;
      height: 2px;
      z-index: 2;
      top: 22px; }
    &:before {
      right: 17px;
      transform: rotate(-45deg); }
    &:after {
      right: 23px;
      transform: rotate(45deg); } } }

.field-error {
  font-size: 12px;
  margin: 3px 0 0; }

.form-buttons {
  margin-top: 20px; }

.form-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.-bottom {
    align-items: flex-end; }
  &.-full {
    justify-content: center; } }

.form-grid_item {
  flex: 0 0 auto;
  & + & {
    margin-left: 15px; } }

.form-link {
  display: inline-block;
  font-size: 13px;
  line-height: 1.2;
  &.-with-label {
    margin-bottom: 5px; } }

.form-help {
  background-color: rgba($color-black, 0.9);
  color: #fff;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 20px; }

.form-help_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.form-help_grid-item {
  flex: 1 1 auto;
  &.-icon {
    flex: 0 0 auto;
    margin-right: 10px; } }

.form-help_icon {
  width: 20px;
  height: 20px; }

.form-help_text {
  font-size: 13px; }
