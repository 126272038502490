/**
 * @license
 * MyFonts Webfont Build ID 3868355, 2020-02-18T11:34:18-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NHaasGroteskTXPro-55Rg by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-text-55-roman/
 *
 * Webfont: NHaasGroteskTXPro-75Bd by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-text-75-bold/
 *
 *
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: Copyright &#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 *
 * © 2020 MyFonts Inc */
/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: 'NHaasGroteskText';
  src: url('../assets/fonts/neue-haas-grotesk-text-55/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-text-55/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskText';
  src: url('../assets/fonts/neue-haas-grotesk-text-75/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-text-75/font.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskDisplay';
  src: url('../assets/fonts/neue-haas-grotesk-display-55/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-display-55/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskDisplay';
  src: url('../assets/fonts/neue-haas-grotesk-display-75/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-display-75/font.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap; }
