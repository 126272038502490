.checkbox {
  display: inline-block;
  width: 16px;
  height: 100%;
  position: relative;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer; }

.verizon-checkbox {
  display: inline-block;
  position: relative;
  height: 16px;
  input[type="checkbox"] {
    z-index: -1;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    &:disabled + .checkbox {
      background-color: #D8DADA; }

    &:checked + .checkbox {
      background-color: #000;
      &:before {
        color: #fff;
        content: url("../assets/images/icons/verizon/i-checkbox.svg");
        position: absolute;
        left: 3px;
        top: 0;
        width: 15px;
        height: 15px; } } } }
