.article-edit {
  font-size: 13px;
  .DraftEditor-root {
    padding-left: 14px;
    padding-right: 14px; } }

.article-edit_list {
  list-style: none;
  padding: 0;
  margin: 0; }

.article-edit_list-item {
  & + & {
    margin-top: 12px;
    &.-bordered {
      border-top: 1px solid #d5d6d6;
      padding-top: 12px; } } }


.article-edit_list-item-grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -8px; }

.article-edit_list-item-col {
  margin: 0 8px;
  &.-control {
    flex: 1 1 auto; }
  &.-button {
    flex: 0 0 auto;
    padding-right: 15px; } }

.article-edit_list-item-control {
  & + & {
    margin-top: 10px; } }

.article-edit_list-button {
  margin-top: 12px; }
