.toggle {
  display: inline-block;
  vertical-align: middle; }

.toggle_label {
  position: relative;
  display: block;
  background-color: $color-gray;
  width: 30px;
  border: 1px solid $color-gray-dark;
  border-radius: 30px;
  padding: 1px;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-left: 0;
    background-color: $color-gray-dark;
    transition: margin-left 0.15s linear, background-color 0.15s linear; } }

.toggle {
  input[type="radio"], input[type="checkbox"] {
    z-index: -1;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    &:checked + .toggle_label {
      &:after {
        background-color: #343333;
        margin-left: 12px; } } } }
