.percents-list {
  padding: 0;
  margin: 0;
  .item-list-right-side {
    min-width: 25px;
    text-align: center; }
  li {
    font-size: 14px;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed $color-black; } }

  li + li {
    padding-top: 10px; }
  li:last-child {
    &:after {
      border-bottom: none; } } }
