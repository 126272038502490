.info-tooltip {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: default;
  &:not(:first-child) {
    margin-left: 8px; }
  &.-force-margin {
    margin-left: 5px; }
  &.-error {
    margin-left: 4px;
    &:hover {
      .info-tooltip_control {
        background-color: $color-danger;
        opacity: 0.7; } }
    .info-tooltip_control, .info-tooltip_drop-inner {
      background-color: $color-danger; } }
  &.-range {
    display: flex;
    flex-direction: row; } }

.info-exclamation-tooltip {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: default;
  background-color: $color-gray;
  padding: 5px;
  border-radius: 22px; }

.info-tooltip_control {
  width: 18px;
  height: 18px;
  background-color: $color-gray;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 15px;
  transition: background-color 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 1px #fff;
  .icon {
    vertical-align: middle; } }

.info-tooltip_exclamation {
  width: 18px;
  height: 18px;
  background-color: #fff;
  color: $color-gray;
  border-radius: 100%;
  text-align: center;
  line-height: 15px;
  transition: background-color 0.15s;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 1px #fff;
  .icon {
    vertical-align: middle; } }

.info-tooltip_label {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.4;
  background: $color-gray;
  color: #fff;
  height: 18px;
  border-radius: 22px;
  padding: 2px 10px;
  margin-left: -14px;
  z-index: 1;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  transition: background-color 0.15s; }

.info-exclamation_label {
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  background: $color-gray;
  color: #fff;
  height: 18px;
  border-radius: 22px;
  padding: 2px 10px;
  margin-left: -14px;
  z-index: 1;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  transition: background-color 0.15s; }


.info-tooltip_drop {
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s, visibility 0.15s;
  z-index: $z-index-tooltip;
  &.-left {
    padding-left: 0;
    padding-right: 15px;
    left: auto;
    right: 100%; }
  &.-top {
    bottom: 18px;
    top: inherit;
    right: 0;
    left: inherit; }
  &.-top-center {
    bottom: 18px;
    top: inherit;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    padding-top: 7px; }
  &.-bottom-center {
    top: 18px;
    bottom: inherit;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    padding-top: 7px;
    &.-date {
      top: 10px; } }
  &.-bottom-left {
    top: 25px;
    bottom: inherit;
    right: 0;
    left: auto; }
  &.-top-left {
    bottom: 8px;
    top: auto;
    right: 0;
    left: auto; }
  &.-big {
    .info-tooltip_drop-inner {
      min-width: 485px; } }
  &.-claims-help-fixed {
    left: -69px;
    top: 39px; }
  &.-concepts-popup {
    padding: 0;
    left: auto;
    right: 0;
    top: 20px;
    bottom: auto; } }

.logic {
  font-weight: bold;
  display: inline-block;
  min-width: 110px; }

.info-tooltip_drop-inner {
  color: #fff;
  background: rgba(80,81,84,0.97);
  font-size: 14px;
  line-height: 1.6;
  font-weight: normal;
  min-width: 260px;
  padding: 20px 25px;
  border-radius: 14px;
  &.-sm {
    min-width: 200px; }
  &.-sizes {
    width: 400px;
    ul {
      margin: 5px 0 0 0;
      padding: 0;
      li {
        list-style: none;
        text-align: left; } } }
  &.-concepts-popup {
    width: 400px; }
  &.-tiny {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    min-width: 100px;
    white-space: nowrap; } }

.info-tooltip_section {
  & + & {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #666; } }


.info-tooltip {
  &:hover {
    .info-tooltip_control, .info-tooltip_label {
      background-color: $color-gray-dark; }
    .info-tooltip_drop {
      opacity: 1;
      visibility: visible; } } }

.info-tooltip_fake-select {
  display: inline-block;
  padding: 1px 10px 2px;
  border-radius: 14px;
  border: 1px solid currentColor;
  margin: 0 5px;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border-top: 3px solid currentColor;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    margin-left: 5px; } }

.info-tooltip {
  .info-tooltip_drop {
    &.-with-img {
      min-width: auto;
      img {
        max-width: 300px; }
      img.-bigger {
        max-width: 400px; }
      .info-tooltip_drop-inner {
        padding: 2px;
        border-radius: 10px;
        .info-tooltip_section {
          padding-top: 5px;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 5px; }
        .info-tooltip_image {
          background: $color-gray-dark;
          border-radius: 0 0 10px 10px; } } } } }
