.button, .uppy-FileInput-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  margin: 0;
  /*transition: all 150ms ease*/
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  box-shadow: none;
  user-select: none;
  color: #ffffff;
  background: #000;
  border: 2px solid #000;
  border-radius: 50px;
  padding: 12px 40px;
  white-space: nowrap;

  &:disabled, &.-disabled {
    background: #D8DADA;
    border-color: #D8DADA;
    //color: #747676
    cursor: not-allowed;
    pointer-events: none; }

  &.-bordered {
    background: transparent;
    color: #000;
    margin-left: 5px;
    &:first-child {
      margin: 0; }
    &:disabled, &.-disabled {
      background: transparent;
      color: #ccc;
      border-color: #bbb; } }

  &.-secondary {
    color: #000;
    background: #fff;
    border: 2px solid #fff;
    &.-bordered {
      background: transparent;
      color: #fff;
      border-color: #fff; } }

  &.-gray {
    background-color: $color-gray-dark;
    border-color: $color-gray-dark; }

  &.-sm {
    font-size: 12px;
    padding: 8px 15px;
    height: auto; }

  &.-xs {
    font-size: 11px;
    padding: 4px 12px;
    height: auto; }

  &.-avatar-upload {
    font-size: 9px;
    width: 90px;
    padding: 4px 0; }

  &.-upper {
    text-transform: uppercase; }

  &.loader {
    user-select: none;

    &.-loading-blink {
      animation: loading-blink 1s infinite; } }

  &.hide {
    display: none; } }

@keyframes loading-blink {
  0%, 100% {
    opacity: .4; }
  50% {
    opacity: 1; } }

.button-link {
  @include button-reset; }

.uppy-FileInput-btn {
  font-size: 12px;
  height: auto;
  margin-bottom: 10px;
  padding: 8px 15px;
  text-transform: uppercase; }
