.icon {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  &.-link-ext {
    width: 14px;
    height: 14px; }
  &.-i-info {
    width: 4px;
    height: 11px; } }
