.portal.spotlight-portal {
  .portal-inner {
    width: 100%;
    height: 100%;
    max-width: 1000px; }
  .portal-container {
    max-height: none;
    height: 100%; }
  .portal-content {
    margin-top: 20px;
    margin-right: 0;
    padding-right: 0;
    padding-top: 15px;
    border-top: 1px solid #d5d6d6; } }

.customers-search {
  h3 {
    margin-bottom: 15px; } }

.customers {
  list-style: none;
  padding: 0;
  margin: 0; }

.customers_item {
  & + & {
    margin-top: $base-gap; } }

.customer {
  background: $color-black;
  color: $color-white;
  padding: 16px;
  position: relative;
  padding-right: 20%; }

.customer_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.customer_avatar {
  width: 242px;
  height: 242px;
  overflow: hidden;
  flex: 0 0 auto;
  margin-right: 20px;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; } }

.customer_info {
  margin-left: 20px;
  width: 100%; }

.customer_name {
  @include font-title;
  font-size: 28px;
  font-weight: bold;
  margin: 5px 0 15px; }

.customer_text {
  font-size: 24px;
  p {
    margin: 0;
    padding: 0; } }

.customer_button {
  margin-top: 20px; }

.customer_close {
  @include button-reset;
  position: absolute;
  padding: 16px;
  top: 0;
  right: 0; }

.customer_close-icon {
  width: 14px;
  height: 14px;
  color: $color-white; }

.customer_edit-icon {
  width: 20px;
  height: 20px;
  color: $color-white; }


