.sidebar {
  flex: 0 0 200px; }

.sidebar_body {
  padding: $base-gap 4px $base-gap $base-gap; }

.sidebar_footer {
  padding: $base-gap; }

.sidebar_section {
  & + & {
    margin-top: 20px; }
  &.-double-space {
    &:not(:first-child) {
      margin-top: 40px; } } }

.sidebar_section-divider {
  height: 2px;
  width: 100%;
  background: $color-gray;
  margin: 20px 0;
  border: 0; }

.sidebar_section-title {
  color: $color-text-primary;
  font-size: 14px;
  @include font-text;
  margin: 0 0 5px; }

.sidebar_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.sidebar_list-link {
  @include font-text;
  color: $color-gray-dark;
  padding: 2px 0;
  font-size: 14px;
  line-height: 2;
  text-decoration: none;
  &.-active {
    border-bottom: 3px solid $color-brand; }
  &.-export {
    line-height: 1; }
  &.-inline-block {
    display: inline-block; }
  span {
    &.-inline-block {
      display: inline-block; }
    &.-low-line-height {
      line-height: 1.3; } } }
