.radio-set-body {
  margin-top: 3px;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px; }

.radio {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  border-radius: 100%;
  top: 2px;
  &:before {
    background-color: #fff;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 100%; } }

.verizon-radio {
  display: inline-block;
  position: relative;
  height: 16px;
  input[type="radio"] {
    z-index: -1;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    &:disabled + .radio {
      background-color: #D8DADA; }

    &:checked + .radio {
      background-color: #000;
      &:after {
        background-color: #000;
        content: '';
        position: absolute;
        left: 3px;
        top: 3px;
        width: 7px;
        height: 7px;
        border-radius: 100%; } } } }
