$message-width: 550px;
$reply-message-shift: 60px;

.whiteboard {
  .post {
    background: #fff;
    padding: 8px;
    margin-bottom: 8px;
    font-size: 13px;

    &.reply {
      margin-left: $reply-message-shift; }

    display: grid;
    grid-template-areas: "avatar username" "avatar message" "avatar replies-toggle";
    grid-template-columns: 60px 1fr;
    grid-template-rows: auto auto;

    .avatar {
      grid-area: avatar; }

    .username {
      grid-area: username; }

    .message {
      max-width: $message-width;
      padding-top: 5px;
      grid-area: message;
      position: relative;
      &.-reply {
        max-width: calc(#{$message-width} - #{$reply-message-shift}); }
      // styles for tags inside message
      p {
        margin: 0;
        & + p {
          margin-top: 0.8em; } }
      ul, ol {
        //".se-" — class for suneditor component
        &:not([class*='se-']) {
          margin: 1em 0;
          padding: 0 0 0 2em;
          li {
            margin: 0;
            padding: 0; } } }
      a {
        text-decoration: underline;
        color: inherit; }

      blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: 5px solid #ccc; } }

    .message_button-send-icon {
      width: 18px;
      height: 18px;
      color: $color-black; }

    .message_panel {
      height: 38px;
      background: #F8F8F8;
      padding: 10px;
      button {
        float: right;
        &:disabled {
          svg {
            color: #D8DADA; } }
        @include button-reset; } }

    .message_panel_button-send {
      width: 18px;
      height: 18px;
      color: $color-black; }

    .replies-toggle {
      padding-top: 5px;
      grid-area: replies-toggle;

      .reply-text {
        color: $color-text-secondary;
        cursor: pointer;
        text-decoration-line: underline; } } } }


.whiteboard_load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 40px;
  &.-box {
    height: 200px; } }

.data-letters {
  display: inline-block;
  font-size: 1.2em;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  margin-right: 1em;
  color: white; }
