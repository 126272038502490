.portal {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  @include mobile {
    overflow-y: auto; }
  @include desktop {
    background-color: rgba($color-black, 0.8);
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 5vh 0;
    &.-no-bg {
      background-color: transparent; } } }

.portal-backdrop {
  @include desktop {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer; } }

.portal-inner {
  &.-fade-out {
    display: none; }
  &.-fade-in {
    display: block; }

  @include desktop {
    width: 100%;
    max-width: 960px;
    padding: 5px 30px;
    flex: 0 0 auto;
    overflow: hidden;
    margin: auto;
    position: relative;
    z-index: 2;
    .portal.-sm & {
      max-width: 640px; } } }

.portal-container {
  @include desktop {
    padding: 20px $base-gap;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    max-height: 90vh;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.25); }
  @include mobile {
    padding: 16px; } }

.portal-header {
  margin-bottom: 10px;
  @include desktop {
    margin-bottom: 0;
    flex: 0 0 auto;
    position: relative; } }

.portal-header_grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }


.portal-content {
  @include desktop {
    flex: 1 1 auto;
    overflow-y: auto;
    padding-right: 20px;
    margin-right: -20px;
    padding-top: 10px; } }

.portal-title {
  color: #000000;
  @include font-title;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1;
  text-transform: uppercase;
  margin: 0; }

.portal-close {
  margin-top: -10px;
  margin-right: -10px;
  margin-left: auto;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  background-color: #fff;
  border-width: 0;
  position: relative; }

.portal-close_icon {
  width: 14px;
  height: 14px;
  color: $color-black; }

.portal-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #d5d6d6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.-no-delete-button {
    justify-content: flex-end; } }

.portal-footer_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .button {
    &.-left {
      margin-right: auto; }
    & + .button {
      margin-left: 10px; } } }

.portal-error {
  background-image: url('../assets/images/verizon-5g/alert.png');
  background-color: $color-danger;
  padding: 10px 45px;
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 20px;
  min-height: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  h3 {
    font-family: $font-title;
    font-size: 18px;
    font-weight: bold; } }
