@mixin  mobile {
  @media (max-width: $bp-sm - 1px) {
    @content; } }

@mixin  mobile-xs {
  @media (max-width: $bp-xs - 1px) {
    @content; } }

@mixin  desktop {
  @media (min-width: $bp-sm) {
    @content; } }

@mixin  desktop-lg-min {
  @media (min-width: $bp-lg) {
    @content; } }

@mixin  desktop-lg-max {
  @media (max-width: $bp-lg) {
    @content; } }

@mixin font-title {
  font-family: $font-title; }

@mixin font-text {
  font-family: $font-text; }

@mixin  button-reset {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none; }

@mixin  container {
  max-width: $container-width + 2*$base-gap;
  margin: auto;
  padding: 0 $base-gap; }



