.customer-spotlight {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  p {
    margin: 0;
    padding: 0;
    min-height: 15px;
    line-height: 1.7; }
  ul, ol {
    padding-left: 17px;
    margin: 10px 0 0; }
  li + li {
    margin-top: 5px; }

  & + & {
    margin-top: 24px; }

  &_title {
    font-family: "NHaasGroteskDisplay", Helvetica, Arial, sans-serif;
    font-size: 32px;
    line-height: 62px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    a {
      font-size: 14px;
      font-family: "NHaasGroteskText", Helvetica, Arial, sans-serif; }

    & > .underline {
      text-decoration: underline; } }

  &_row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 16px 0; }

  &_column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    &.-primary {
      flex-basis: calc(70% - 24px);
      flex-grow: 1; }

    &.-secondary {
      margin-right: 24px;
      flex-shrink: 0;
      flex-basis: 30%;
      max-width: 30%;

      img {
        width: 100%;
        height: auto; } } }

  &_install-type {
    font-family: "NHaasGroteskDisplay", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 28px;
    &.-no-left-side {
      padding-left: 16px;
      padding-right: 16px; }
    &.-no-better-experience {
      padding-left: 0;
      padding-right: 0; } }

  &_notes {
    margin-top: 16px;
    line-height: 1.7; }

  &_video {
    margin-top: 16px;
    width: 100%;
    border: 1px solid $color-black;
    height: 100%;
    max-height: 175px;
    min-height: 175px;
    position: relative;
    cursor: pointer;
    &.-overview {
      background: #000;
      border-color: #fff;
      &:before {
        filter: invert(100%); }
      & + .customer_text {
        margin-top: 15px; } }
    &:before {
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: "";
      position: absolute;
      background: url('../assets/images/icons/verizon-5g/video.svg') no-repeat 50% 50%; } }

  &_description {
    padding: 16px 0;
    &.-no-left-side {
      padding-left: 16px;
      padding-right: 16px; }
    &.-no-better-experience {
      padding-left: 0;
      padding-right: 0; } }

  &_self-improve {
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
    background-color: #E2E4E3;
    line-height: 1.7;
    &.-no-left-side {
      margin-left: 0;
      margin-right: 0; } } }

.portal-video {
  width: 100%;
  min-height: 300px; }

.with-spotlight-note > .customer-spotlight-note {
  margin-bottom: 10px; }

.customer-spotlight-note {
  color: $color-gray-dark;
  font-size: 12px;
  & + .customer-spotlight, & + .spotlights-blank {
    margin-top: 10px; } }
