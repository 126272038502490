.article_title {
  @include font-title;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 25px; }

.article {
  &.-installation {
    max-width: 684px;
 } }    //margin: 0 auto

.article_content {
  font-size: 14px;
  p {
    margin: 0; }
  p + p {
    margin-top: 10px; }
  a {
    text-decoration: underline;
    color: inherit; }
  &.-type-grid {
    ul, ol {
      margin: 1em 0;
      padding: 0 0 0 2em;
      li {
        margin: 0;
        padding: 0; } } }
  &.-type--list {
    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        counter-increment: inst;
        padding-left: 100px;
        min-height: 75px;
        position: relative;
        display: flex;
        align-items: center;
        &:before {
          content: counter(inst);
          width: 100px;
          height: 100%;
          //background-color: $color-gray
          border-radius: 100%;
          text-align: center;
          line-height: 76px;
          font-size: 60px;
          font-weight: bold;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0; } } } }
  li + li {
    margin-top: 10px; }

  &.-installation-slides {
    .slick-next {
      right: -24px;
      top: 50%;
      transform: translateY(-50%); } } }

.article_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -12px -20px 0; }

.article_grid-col {
  flex: 0 0 33%;
  padding: 12px 20px 30px;
  min-width: 0; }

.article_grid-col-title {
  @include font-title;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid currentColor;
  margin-bottom: 10px; }

.article_grid-col-text {
  font-size: 13px;
  ul, ol {
    padding-left: 17px;
    margin: 10px 0 0; }
  li + li {
    margin-top: 5px; } }

.v5g-iframe {
  border: 0 solid transparent; }
