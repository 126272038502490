.pulses.section {
  .slick-next {
    right: -25px;
    top: 50%;
    transform: translateY(-50%); } }

.pulses_wrapper-table {
  overflow-x: auto;
  height: auto;
  &.-full {
    width: 100%;
    table {
      width: 100%; } }
  &.-wide {
    margin-right: -40px;
    @include desktop-lg-min {
      margin-right: calc(-50vw + 580px); } } }

.pulses_button {
  margin-top: 20px; }

