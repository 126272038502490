.active-report_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -12px;
  flex-wrap: wrap;
  &.-with-top-margin {
    margin-top: 20px; } }

.active-report_grid-col {
  flex: 0 0 25%;
  padding: 12px;
  min-width: 0;
  @include desktop-lg-max {
    flex: 1 0 50%;
    max-width: 300px;
    min-width: 250px; } }

.active-report_item-header {
  font-size: 14px;
  font-weight: bold;
  @include font-title;
  padding-bottom: 10px;
  border-bottom: 2px solid currentColor;
  margin-bottom: 30px;
  height: 50px;
  line-height: 1.2;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between; }

.active-report_logo-bb {
  width: 99px;
  height: 55px;
  background-image: url('../assets/images/verizon-5g/bb-logo-colored.png');
  background-position: 0 0;
  background-size: contain; }
