.circle-title {
  text-align: center;
  h4 {
    font-size: 24px; }
  &.-with-line {
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 30px; } }
.circle-content {
  width: 100%;
  text-align: center;
  padding-top: 10px; }

.base-percent-text, .second-percent-text, .third-percent-text {
  font-family: $font-text;
  font-weight: bold; }
.base-percent-text {
  font-size: 9px; }

.second-percent-text {
  font-size: 3px; }

.third-percent-text {
  font-size: 3px; }
