.legend {
  margin-top: 10px;
  .coma {
    color: #000; }
  .legend-item + .legend-item {
    margin-left: 5px; }
  .legend-item {
    font-weight: bold;
    &.legend-mm-wave {
      color: $color-mm-wave; }
    &.legend-c-band {
      color: $color-c-band; } }
  .legend-note {
    margin-top: 3px;
    font-size: 10px; } }
