.table {
  width: 100%;
  border-collapse: collapse;
  th {
    text-align: left;
    color: $color-gray-dark;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #d5d6d6;
    padding: 12px 20px; }
  td {
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    padding: 12px 20px; }
  &.-striped {
    tr:nth-child(odd) {
      td {
        background-color: $color-gray; } } }
  &.-pointer {
    cursor: pointer; } }

.table_tr-clickable {
  position: relative;
  &.-pointer {
    cursor: pointer; } }

.table_full-line-clickable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  cursor: pointer; }

.table_control-over-line {
  position: relative;
  z-index: 1; }
